import React, { useContext, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Preview
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageType } from "../components/context/page-type";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Utils
import { ImageOrientation } from "../components/utils/image-orientation";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { useColorContext } from "../components/context/color-context";

const Page = styled.div`
  margin: 40px 0 0 0;

  @media (max-width: 900px) {
    margin: 55px 0 0 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 80px;

  margin: 70px 0;

  @media (max-width: 768px) {
    grid-row-gap: 40px;

    grid-column-gap: unset;
    grid-auto-rows: unset;
  }
`;

const ContentContainer = styled.div`
  & .single-partner-container {
    grid-column: span 2;

    & .inner-grid {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      height: 100%;
    }

    & .name {
      min-height: 1em;
      // min-height: 60px;

      @media (max-width: 768px) {
        min-height: unset;
      }
    }

    & .text-image-container {
      flex: 1;

      & .image-container {
        position: relative;
        margin: 1em 0;
      }

      & img {
        margin: 0 auto;

        object-fit: contain;

        top: 50%;
        transform: translateY(-50%);
        position: relative;

        @media (max-width: 768px) {
          top: unset;
          transform: unset;
        }

        &.landscape {
          width: 100%;
          height: 100%;
          max-height: calc(100% - 30px - 30px);

          @media (max-width: 768px) {
            max-height: 100%;
          }
        }

        &.portrait {
          max-width: 100%;
          max-height: 100%;
        }
      }

      & .text {
        &.has-read-more-text {
          &.is-closed {
            cursor: url("/icons/read-more@1x.png") 50 0, pointer;
            cursor: -webkit-image-set(
                  url("/icons/read-more@1x.png") 1x,
                  url("/icons/read-more@2x.png") 2x
                )
                50 0,
              pointer;
            cursor: url("/icons/read-more.svg"), 50 0, pointer;
          }
          &.is-open {
            cursor: url("/icons/read-less@1x.png") 50 0, pointer;
            cursor: -webkit-image-set(
                  url("/icons/read-less@1x.png") 1x,
                  url("/icons/read-less@2x.png") 2x
                )
                50 0,
              pointer;
            cursor: url("/icons/read-less.svg"), 50 0, pointer;
          }
        }
      }

      & .read-more-text {
        &.is-open {
          cursor: url("/icons/read-less@1x.png") 50 0, pointer;
          cursor: -webkit-image-set(
                url("/icons/read-less@1x.png") 1x,
                url("/icons/read-less@2x.png") 2x
              )
              50 0,
            pointer;
          cursor: url("/icons/read-less.svg"), 50 0, pointer;
        }
      }

      & .text {
        & p {
          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      grid-column: span 3;
    }

    @media (max-width: 768px) {
      grid-column: 1 / 7;
    }
  }
`;

const MobileReadMore = styled.button`
  margin: 1em 0 0 0;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
`;

const Partners = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);
  let isMobile = useMediaQuery("(max-width: 768px)");

  const [activePartner, setActivePartner] = useState(null);

  useEffect(() => {
    setPageType(`partners`);
  }, []);

  if (data.prismicPartners === null) return <></>;

  const content = data.prismicPartners.data.partners.map((partner, index) => (
    <div
      key={`single_image_partner${index}`}
      className="single-partner-container"
    >
      <div className="inner-grid">
        <div
          className="name uppercase"
          dangerouslySetInnerHTML={{
            __html: partner.partner_title.html,
          }}
        />

        <div className="text-image-container">
          <div className="image-container">
            <AspectRatioImageContainer image={null} padding={`61`}>
              {partner.partner_image.fluid !== null &&
                partner.partner_image.fluid !== undefined && (
                  <img
                    className={`${ImageOrientation(partner.partner_image)}`}
                    srcSet={partner.partner_image.fluid.srcSetWebp}
                    src={partner.partner_image.fluid.srcWebp}
                    alt={partner.partner_image.alt}
                    loading={`lazy`}
                  />
                )}
            </AspectRatioImageContainer>
          </div>

          {isMobile ? (
            <>
              <div
                className={`text ${
                  partner.partner_read_more_text.text.length >= 1
                    ? `has-read-more-text`
                    : ``
                }${activePartner === index ? ` is-open` : ` is-closed`}`}
                dangerouslySetInnerHTML={{
                  __html: partner.partner_text.html,
                }}
              />

              {partner.partner_read_more_text.text.length >= 1 &&
                activePartner !== index && (
                  <MobileReadMore onClick={() => setActivePartner(index)}>
                    Read more
                  </MobileReadMore>
                )}
            </>
          ) : (
            <div
              onClick={() => {
                if (activePartner === index) {
                  setActivePartner(null);
                } else {
                  setActivePartner(index);
                }
              }}
              className={`text ${
                partner.partner_read_more_text.text.length >= 1
                  ? `has-read-more-text`
                  : ``
              }${activePartner === index ? ` is-open` : ` is-closed`}`}
              dangerouslySetInnerHTML={{
                __html: partner.partner_text.html,
              }}
            />
          )}

          {activePartner === index && (
            <>
              <div
                onClick={() => setActivePartner(null)}
                className="read-more-text is-open"
                dangerouslySetInnerHTML={{
                  __html: partner.partner_read_more_text.html,
                }}
              />
              {partner.partner_read_more_text.text.length >= 1 && (
                <MobileReadMore onClick={() => setActivePartner(null)}>
                  Read less
                </MobileReadMore>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <Helmet
        title={`${data.prismicPartners.data.title.text} | Three Rivers | Bexley`}
        meta={[
          {
            name: "title",
            content: `${data.prismicPartners.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "og:title",
            content: `${data.prismicPartners.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicPartners.data.title.text} | Three Rivers | Bexley`,
          },
        ]}
      />
      <Page>
        <ContentContainer>
          <Grid>{content}</Grid>
        </ContentContainer>
      </Page>
    </>
  );
};

export default withPreview(Partners);

export const query = graphql`
  query {
    prismicPartners {
      _previewable
      data {
        title {
          html
          text
        }
        partners {
          partner_title {
            html
          }
          partner_image {
            alt
            dimensions {
              height
              width
            }
            fluid {
              srcWebp
              srcSetWebp
            }
          }
          partner_text {
            html
          }
          partner_read_more_text {
            html
            text
          }
        }
      }
    }
  }
`;
